export default [
  "modules/accordions",
  "modules/details-summaries",
  //"modules/filtered-posts-list",
  "modules/game-characters",
  "modules/game-header",
  "modules/game-keyart",
  //"modules/google-map",
  "modules/language-switcher",
  //"modules/lottie",
  "modules/media-pack-single--intro",
  "modules/media-pack-single--soundtracks",
  "modules/mouse-hover-progress",
  //"mouse-hover-progress",
  //"modules/newsletter",
  "modules/pb-row-games",
  "modules/pb-row-medias",
  "modules/pb-row-news",
  //"modules/pb-row-oembed",
  "modules/pb-row-tabs",
  "modules/pb-row-testimonials",
  "modules/pb-row-video-player",
  // "modules/pb-row-text-media",
  //"modules/pb-row-wrapper-reveal",
  //"modules/post-archives",
  //"modules/prefers-color-scheme-btn",
  "modules/rive-animation",
  //"modules/scrolling-images-sequence",
  //"modules/sharing",
  "modules/soundtracks",
  //"modules/svg-path-length",
  //"modules/text-ticker",
  "modules/scrolling-animated-text",
  "modules/title-highlight-decoration",
  "modules/video"
];
